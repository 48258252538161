import * as React from 'react';
import { FC, useCallback } from 'react';
import {
    Show,
    ReferenceField,
    TabbedShowLayout,
    Tab,
    TextField,
    useRecordContext,
} from 'react-admin';

import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Course, FieldProps } from '../types';

import CourseStatusField from './fields/CourseStatutField';
import CodeField from './fields/CodeField';
import DateTimeField from '../fields/common/DateTimefield';
import DistanceField from '../fields/common/DistanceField';
import DurationField from '../fields/common/DurationField';
import FullNameField from '../fields/users/FullNameField';
import PriceField from '../fields/common/PriceField';

import PayCourseBtn from './btns/PayCourseBtn';

import CourseEventList from './CourseEventsList';

const Title: FC<FieldProps<Course>> = ({ record }) =>
    record ? <Typography>Réservation</Typography> : null;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detailsBox: {
            paddingLeft: '50px',
            marginLeft: '50px',
        },
        element: {
            marginRight: '30px',
        },
        section: {
            boxShadow: '-5px 5px 15px #ccc',
            padding: '20px',
            marginTop: '20px',
            margin: '20px',
            flexBasis: '300px',
        },
        photosSection: {
            boxShadow: '-5px 5px 15px #ccc',
            padding: '20px',
            marginTop: '20px',
            margin: '20px',
            flexBasis: '100%',
        },
        headerSection: {
            fontWeight: 700,
            backgroundColor: '#eee',
            padding: '20px',
        },
        sectionCol: {
            flexGrow: 0,
            width: '25%',
            marginBottom: '10px',
        },
        boxTitle: {
            width: '100%',
            marginBottom: '10px',
            flexShrink: 0,
        },
    })
);

const can_pay = statut => ['finished', 'not_payed'].includes(statut);

const PayCourseForm = () => {
    const record = useRecordContext();
    const { id, statut } = record;
    const handleChange = useCallback(() => true, []);

    return can_pay(statut) ? (
        <>
            <PayCourseBtn onChange={handleChange} courseId={id} />
        </>
    ) : null;
};
/**
 * Start Inspection Tab View
 */
const CourseDetailsSection = (props: any) => {
    const classes = useStyles();
    const { record } = props;
    const driverComponent =
        record.driver !== null ? (
            <ReferenceField
                label="resources.courses.fields.driver"
                source="driver"
                reference="drivers"
                className={classes.sectionCol}
            >
                <FullNameField />
            </ReferenceField>
        ) : (
            <></>
        );
    return (
        <BoxedShowLayout>
            <RaBox display="flex" flexWrap="wrap" className={classes.section}>
                <Typography variant="h6" className={classes.boxTitle}>
                    Course Details
                </Typography>
                <CodeField
                    addLabel={true}
                    label="resources.courses.fields.code"
                    className={classes.sectionCol}
                    source="code"
                />
                <DateTimeField
                    source="created_at"
                    addLabel={true}
                    label="resources.courses.fields.created_at"
                    className={classes.sectionCol}
                />
                <ReferenceField
                    label="resources.courses.fields.user"
                    source="user"
                    reference="users"
                    className={classes.sectionCol}
                >
                    <FullNameField />
                </ReferenceField>
            </RaBox>
            <RaBox display="flex" flexWrap="wrap" className={classes.section}>
                <Typography variant="h6" className={classes.boxTitle}>
                    Vehicle Details
                </Typography>
                <ReferenceField
                    label="resources.courses.fields.vehicle_type"
                    source="vehicle_type"
                    reference="vehicles"
                    className={classes.sectionCol}
                >
                    <TextField source="name" />
                </ReferenceField>

            </RaBox>
            <RaBox display="flex" flexWrap="wrap" className={classes.section}>
                <Typography variant="h6" className={classes.boxTitle}>
                    Estimated Price Details
                </Typography>

                <TextField
                    addLabel={true}
                    label="Distance"
                    className={classes.sectionCol}
                    source="calculedestimatedPrice.distance_str"
                />
                <TextField
                    addLabel={true}
                    label="Duration"
                    className={classes.sectionCol}
                    source="calculedestimatedPrice.duration_str"
                />
                <TextField
                    addLabel={true}
                    source="calculedestimatedPrice.estimatedPrice_str"
                    label="Estimated Price"
                />
            </RaBox>
            <RaBox display="flex" flexWrap="wrap" className={classes.section}>
                <Typography variant="h6" className={classes.boxTitle}>
                    Additional Details
                </Typography>
                <TextField
                    addLabel={true}
                    label="resources.courses.fields.from_address"
                    className={classes.sectionCol}
                    source="from_address"
                />
                <TextField
                    addLabel={true}
                    label="resources.courses.fields.to_address"
                    className={classes.sectionCol}
                    source="to_address"
                />
                <TextField
                    addLabel={true}
                    label="resources.courses.fields.google_distance"
                    className={classes.sectionCol}
                    source="google_distance"
                />
                <TextField
                    addLabel={true}
                    label="resources.courses.fields.google_duration"
                    className={classes.sectionCol}
                    source="google_duration"
                />
            </RaBox>
            <RaBox display="flex" flexWrap="wrap" className={classes.section}>
                <Typography variant="h6" className={classes.boxTitle}>
                    Course Status
                </Typography>
                <RaBox className={classes.sectionCol}>
                    <CourseStatusField
                        addLabel={true}
                        label="resources.courses.fields.statut"
                        className={classes.sectionCol}
                    />
                    <PayCourseForm />
                </RaBox>
                <RaBox display="flex" flexWrap="wrap" flexGrow="1">
                    {driverComponent}

                    <DistanceField
                        addLabel={true}
                        label="resources.courses.fields.real_distance"
                        className={classes.sectionCol}
                        source="real_distance"
                    />
                    <DurationField
                        addLabel={true}
                        label="resources.courses.fields.real_duration"
                        className={classes.sectionCol}
                        source="real_duration"
                    />
                    <PriceField
                        addLabel={true}
                        source="price"
                        label="resources.courses.fields.price"
                    />
                </RaBox>
            </RaBox>
        </BoxedShowLayout>
    );
};
/**
 * Show Component
 */
const CourseShow = (props: any) => {
    return (
        <Show {...props} component="div" tltle={<Title />}>
            <TabbedShowLayout>
                <Tab label="Detail ">
                    <CourseDetailsSection {...props} />
                </Tab>
                <Tab label="Historique">
                    <CourseEventList />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default CourseShow;
