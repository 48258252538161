import React from 'react';

import { DateField } from 'react-admin';

const DateTimeField = props => {
    return (
        <DateField
            {...props}
            options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            }}
        />
    );
};

export default DateTimeField;
