import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery, Theme } from '@material-ui/core';
import {
    useTranslate,
    MenuItemLink,
    DashboardMenuItem,
    usePermissions,
} from 'react-admin';

import users from '../users';
import drivers from '../drivers';
import operators from '../operators';
import courses from '../courses';
import vehicles from '../vehiculeTypes';

// import DashboardMenuItem from './DashboardMenuItem';

import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuDelivery';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/courses`}
                primaryText={translate(`resources.courses.name`, {
                    smart_count: 2,
                })}
                leftIcon={<courses.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/vehicles`}
                primaryText={translate(`resources.VehiculeTypes.name`, {
                    smart_count: 2,
                })}
                leftIcon={<vehicles.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/drivers`}
                primaryText={translate(`resources.drivers.name`, {
                    smart_count: 2,
                })}
                leftIcon={<drivers.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {permissions === 'admin' && (
                <MenuItemLink
                    to={`/operators`}
                    primaryText={translate(`resources.operators.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<operators.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
