import * as React from 'react';
import { useRecordContext, Loading, useGetOne } from 'react-admin';
import FullNameField from './FullNameField';

const AdminReferenceField = props => {
    const record = useRecordContext();

    const { data, isLoading } = useGetOne('admins', record[props.source]);

    return isLoading ? (
        <Loading />
    ) : (
        <FullNameField source="name" record={data} />
    );
};
export default AdminReferenceField;
