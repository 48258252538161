import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    BooleanInput,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Pc, FieldProps } from '../types';
import { NumberInput } from 'react-admin';
// import MyImageInput from '../inputs/MyImageInput';

const VehiculeTypeCreate = (props: any) => {
    return (
        <Create title={<PcTitle />} component="div" {...props}>
            <VehiculeTypeForm />
        </Create>
    );
};

const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const VehiculeTypeForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];
    const validateTarif = [required(), minLength(1)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="name"
                                        source="name"
                                        resource="vehicles"
                                        label="resources.VehiculeTypes.fields.name"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <NumberInput
                                        source="tarif_km"
                                        label="resources.VehiculeTypes.fields.tarif_km"
                                        validate={validateTarif}
                                        fullWidth
                                    />
                                    <NumberInput
                                        source="tarif_min"
                                        label="resources.VehiculeTypes.fields.tarif_min"
                                        validate={validateTarif}
                                        fullWidth
                                    />
                                    {/* <MyImageInput
                                        source="image"
                                        label="resources.VehiculeTypes.fields.image"
                                        validate={[required()]}
                                    /> */}
                                    <BooleanInput
                                        source="is_enabled"
                                        label="resources.VehiculeTypes.fields.is_enabled"
                                        defaultValue={true}
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="vehicles"
                        />
                    </form>
                </Card>
            )}
        />
    );
};


export default VehiculeTypeCreate;
