import * as React from 'react';
import {
    Create,
    Toolbar,
    FormWithRedirect,
    TextInput,
    useTranslate,
    PasswordInput,
    required,
    email,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

export const validatePasswords = ({
    password,
    confirm_password,
}: {
    password: string;
    confirm_password: string;
}) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.operators.errors.password_mismatch',
        ];
    }

    return errors;
};

const UserCreate = (props: any) => {
    return (
        <Create undoable={false} component="div" {...props}>
            <UserForm />
        </Create>
    );
};

const UserForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.operators.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="name.first"
                                        label="resources.operators.fields.name.first"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="name.last"
                                        label="resources.operators.fields.name.last"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="email"
                                        source="email"
                                        label="resources.operators.fields.email"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="phone"
                                        source="phone"
                                        label="resources.operators.fields.phone"
                                        resource="admins"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.operators.fieldGroups.change_password'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                        />
                    </form>
                </Card>
            )}
        />
    );
};
const requiredValidate = [required()];

export default UserCreate;
