import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.courses.course_statut.${item}`),
    }));
const CourseStatutInput = props => {
    const translate = useTranslate();
    const statuts = [
        'in_search',
        'driver_notified',
        'conceled_by_client',
        'conceled_by_driver',
        'in_place',
        'accepted',
        'finished',
        'payed',
        'not_payed',
    ];
    return (
        <SelectInput
            choices={toChoices(statuts, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default CourseStatutInput;
