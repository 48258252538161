import React from 'react';
import { Datagrid, DateField, List, useRecordContext } from 'react-admin';
import CourseStatutField from './fields/CourseStatutField';
import CourseEventSourceField from './fields/CourseEventSourceField';

const CourseEventList = (props: any) => {
    const record = useRecordContext();
    return (
        <List
            {...props}
            filters={false}
            resource="course_events"
            filter={{ course: record.id }}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
            hasCreate={false}
        >
            <Datagrid optimized rowClick={false}>
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.course_events.fields.created_at"
                    showTime
                    options={{
                        hourCycle: 'h23',
                    }}
                />

                <CourseStatutField
                    source="to_statut"
                    label="resources.course_events.fields.to_statut"
                />
                <CourseEventSourceField
                    source="source"
                    label="resources.course_events.fields.source"
                />
            </Datagrid>
        </List>
    );
};

export default CourseEventList;
