import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

import List from './CourseList';
import show from './CourseShow';
import Create from './CourseCreate';

export default {
    list: List,
    show: show,
    create: Create,
    icon: DirectionsCarIcon,
};
