import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import FullNameField from '../fields/users/FullNameField';
import CourseStatutField from './fields/CourseStatutField';
import CourseStatutInput from './inputs/CourseStatutInput';
const CourseFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <CourseStatutInput
            source="statut"
            label="resources.courses.fields.statut"
        />
        <ReferenceInput
            source="user"
            label="resources.courses.fields.user"
            reference="users"
        >
            <AutocompleteInput optionText="name" emptyText="Tout" resettable />
        </ReferenceInput>
        <ReferenceInput
            source="driver"
            label="resources.courses.fields.driver"
            reference="drivers"
        >
            <AutocompleteInput optionText="name" emptyText="Tout" resettable />
        </ReferenceInput>
    </Filter>
);
const CourseList = (props: any) => {
    return (
        <List
            {...props}
            filters={<CourseFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
            hasCreate={false}
        >
            <Datagrid optimized rowClick="show">
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.courses.fields.created_at"
                    showTime
                    options={{
                        hourCycle: 'h23',
                    }}
                />
                <TextField
                    source="code_str"
                    label="resources.courses.fields.code"
                />
                <ReferenceField
                    source="user"
                    reference="users"
                    label="resources.courses.fields.user"
                >
                    <FullNameField source="name" />
                </ReferenceField>

                <ReferenceField
                    source="driver"
                    reference="drivers"
                    label="resources.courses.fields.driver"
                >
                    <FullNameField source="name" />
                </ReferenceField>
                <CourseStatutField
                    source="statut"
                    label="resources.courses.fields.statut"
                />
            </Datagrid>
        </List>
    );
};

export default CourseList;
