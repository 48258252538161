import React from 'react';

import { useRecordContext } from 'react-admin';

const DurationField = props => {
    const record = useRecordContext();
    const duration = record[props.source];
    let duration_str = '';
    const h = Math.floor(duration / 3600);
    const min = Math.round((duration - h * 3600) / 60);
    if (h) duration_str += h + ' h ';
    if (min) duration_str += min + ' min ';
    if (!h && !min) duration_str += duration + ' sec';

    return <div>{duration_str}</div>;
};

export default DurationField;
