import * as React from 'react';
import {
    useRecordContext,
    usePermissions,
    ReferenceField,
    Loading,
    useGetOne,
} from 'react-admin';
import FullNameField from './FullNameField';

const OperatorReferenceField = props => {
    const record = useRecordContext();
    const { permissions } = usePermissions();

    const { data, isLoading } = useGetOne('operators', record[props.source]);

    return permissions === 'admin' ? (
        <ReferenceField
            source={props.source}
            reference="operators"
            label="resources.courses_events.fields.source"
        >
            <FullNameField source="name" />
        </ReferenceField>
    ) : isLoading ? (
        <Loading />
    ) : (
        <FullNameField source="name" record={data} />
    );
};
export default OperatorReferenceField;
