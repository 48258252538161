import React from 'react';

import { NumberField } from 'react-admin';

const DistanceField = props => {
    return (
        <div>
            <NumberField {...props} options={{ maximumFractionDigits: 0 }} /> km
        </div>
    );
};

export default DistanceField;
