import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
    NumberField,
    BooleanField
} from 'react-admin';
import Thumbnailfield from './fields/ThumbnailField';

const PcFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const VehiculeTypeList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PcFilter />}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField
                    source="name"
                    label="resources.VehiculeTypes.fields.name"
                />
                <Thumbnailfield
                    source="image"
                    label="resources.VehiculeTypes.fields.image"
                />                <NumberField
                    source="tarif_km"
                    label="resources.VehiculeTypes.fields.tarif_km"
                />
                <NumberField
                    source="tarif_min"
                    label="resources.VehiculeTypes.fields.tarif_min"
                />
                <BooleanField
                    source="is_enabled"
                    label="resources.VehiculeTypes.fields.is_enabled"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.VehiculeTypes.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default VehiculeTypeList;
