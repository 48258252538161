import * as React from 'react';
import { useRecordContext, ReferenceField } from 'react-admin';

import FullNameField from '../../fields/users/FullNameField';
import OperatorReferenceField from '../../fields/users/OperatorReferenceField';
import AdminReferenceField from '../../fields/users/AdminReferenceField';

import { Record } from 'ra-core';
interface Props {
    record?: Record;
    basePath?: string;
    label?: string;
}
const CourseEventSourceField = props => {
    const record = useRecordContext();

    if (record) {
        const source_type = record.source_type;

        switch (source_type) {
            case 'User':
                return (
                    <ReferenceField
                        source="source"
                        reference="users"
                        label="resources.courses_events.fields.source"
                    >
                        <FullNameField source="name" />
                    </ReferenceField>
                );
            case 'Driver':
                return (
                    <ReferenceField
                        source="source"
                        reference="drivers"
                        label="resources.courses_events.fields.source"
                    >
                        <FullNameField source="name" />
                    </ReferenceField>
                );

            case 'Operator':
                return (
                    <OperatorReferenceField
                        source="source"
                        label="resources.courses_events.fields.source"
                    />
                );
            case 'Admin':
                return (
                    <AdminReferenceField
                        source="source"
                        label="resources.courses_events.fields.source"
                    />
                );
            default:
                return null;
        }
    }

    return null;
};
export default CourseEventSourceField;
