import React from 'react';
import { Datagrid, TextField, Filter, List, SearchInput } from 'react-admin';

import FullNameField from '../fields/users/FullNameField';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';

const UserFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const UserList = (props: any) => {
    return (
        <List
            {...props}
            filters={<UserFilter />}
            filter={{ role: 'operator' }}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
            // aside={<UserListAside />}
        >
            <Datagrid optimized rowClick="edit">
                <FullNameField
                    source="name"
                    label="resources.admins.fields.name"
                />
                <TextField
                    source="email"
                    label="resources.admins.fields.email"
                />
                <TextField
                    source="phone"
                    label="resources.admins.fields.phone"
                />
                <ColoredBooleanField
                    source="account_enabled"
                    label="resources.admins.fields.account_enabled"
                />
            </Datagrid>
        </List>
    );
};

export default UserList;
