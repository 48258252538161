import * as React from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
    BooleanInput,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { NumberInput } from 'react-admin';
import MyImageInput from '../inputs/MyImageInput';

const VehiculeTypeEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            undoable={false}
            {...props}
        >
            <VehiculeTypeForm />
        </Edit>
    );
};

const PcTitle = ({ record }: { record?: any }) =>
    record ? <span>{`Edit ${record.name}`}</span> : null;

const VehiculeTypeForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];

    const handleSave = (values, basePath, redirectTo) => {
        const { _id, created_at, id, ...payload } = values;  // Destructure out _id and created_at

        props.save(payload, basePath, redirectTo);
    }
    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            save={handleSave}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display="block">
                                <TextInput
                                    source="name"
                                    resource="vehicles"
                                    label="resources.VehiculeTypes.fields.name"
                                    validate={validateName}
                                    fullWidth
                                />
                                <NumberInput
                                    source="tarif_km"
                                    label="resources.VehiculeTypes.fields.tarif_km"
                                    fullWidth
                                />
                                <NumberInput
                                    source="tarif_min"
                                    label="resources.VehiculeTypes.fields.tarif_min"
                                    fullWidth
                                />
                                <MyImageInput
                                    source="image"
                                    label="resources.VehiculeTypes.fields.image"
                                    validate={[required()]}
                                />
                                <BooleanInput
                                    source="is_enabled"
                                    label="resources.VehiculeTypes.fields.is_enabled"
                                />
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="vehicles"
                        />
                    </form>
                </Card>
            )}
        />
    );
};


export default VehiculeTypeEdit;
