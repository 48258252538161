import { Typography, makeStyles, Button, Grid } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    documentContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: theme.spacing(1),
    },
    title: {
        fontWeight: 500,
    },
    linkButton: {
        color: '#fff',
        backgroundColor: '#2181C9',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#176AA3', // slightly darker shade on hover
        }
    },
    gridContainer: {
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: { // For mobile screens
            flexDirection: 'column',
        }
    },
}));

const DocumentLink = ({ title, id }) => {
    const API_URL = process.env.REACT_APP_REST_API_URL;
    const viewLink = id ? `${API_URL}/files/${id}/view` : undefined;
    const downloadLink = id ? `${API_URL}/files/${id}/download` : undefined;

    const classes = useStyles();

    return (
        <Grid item xs={6}>
            <div className={classes.documentContainer}>
                <Typography variant="body1" gutterBottom className={classes.title}>
                    {title}
                </Typography>
                {id ? (
                    <>
                        <Button
                            component="a"
                            href={viewLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="contained"
                            className={classes.linkButton}
                            disabled={!id}
                        >
                            View
                        </Button>

                        <Button
                            component="a"
                            href={downloadLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="contained"
                            className={classes.linkButton}
                            disabled={!id}
                        >
                            Download
                        </Button>

                    </>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        (Aucune image n'est associée à ce document)
                    </Typography>
                )}
            </div>
        </Grid>
    );
};

export default DocumentLink;
