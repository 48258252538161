import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from '../../types';
import { Typography } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
interface Props extends FieldProps<any> {
    decimalCount?: number;
    decimal?: string;
    thousands?: string;
    currency?: string;
    inline?: boolean;
}

const PriceField: FC<Props> = props => {
    const {
        source = '',
        decimalCount = 2,
        decimal = '.',
        thousands = ' ',
        currency = 'CAD',
        inline = false,
    } = props;
    const record = useRecordContext(props);
    if (inline)
        return record ? (
            <span>
                {`${formatMoney(
                    record[source],
                    decimalCount,
                    decimal,
                    thousands
                )} ${currency}`}
            </span>
        ) : null;
    return record ? (
        <Typography variant="body2" align="right" color="textPrimary">
            {`${formatMoney(
                record[source],
                decimalCount,
                decimal,
                thousands
            )} ${currency}`}
        </Typography>
    ) : null;
};
const formatMoney = (
    amount,
    decimalCount = 2,
    decimal = '.',
    thousands = ' '
) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(Number(amount) - Number(i))
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (e) {
        console.error(e);
    }
};
export default PriceField;
