import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    DateInput,
    PasswordInput,
    NumberInput,
    Toolbar,
    useTranslate,
    minValue,
    maxValue,
    FormWithRedirect,
    ReferenceInput,
    Show,
    TabbedShowLayout,
    Tab,
    AutocompleteInput,
    required,
    email,
    BooleanInput,
} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    Typography,
    makeStyles,
    Grid,
} from '@material-ui/core';
import DocumentLink from './DocumentLink';

import Aside from './Aside';
import FullNameField from '../fields/users/FullNameField';
import { validatePasswords } from './UserCreate';
import { Customer, FieldProps } from '../types';
import CivilityInput from '../inputs/users/CivilityInput';

const useStyles = makeStyles(theme => ({
    documentContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: theme.spacing(1),
    },
    title: {
        fontWeight: 500,
    },
    documentTitle: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        marginBottom: theme.spacing(2),
    },
    linkButton: {
        color: '#fff',
        backgroundColor: '#2181C9',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#176AA3',
        },
    },
    gridContainer: {
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            // For mobile screens
            flexDirection: 'column',
        },
    },
    customCardContent: {
        padding: theme.spacing(3),
        backgroundColor: '#f9f9f9', // a light gray background for slight contrast
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // subtle shadow
        borderRadius: '5px', // rounded corners
        transition: 'box-shadow .3s', // smooth transition for hover effects
        '&:hover': {
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        },
    },
}));

const EditUser = (props: any) => {
    return (
        <Edit aside={<Aside />} undoable={false} component="div" {...props}>
            <UserForm />
        </Edit>
    );
};

const requiredValidate = [required()];

const UserTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
    const translate = useTranslate();
    const validateCommission = [
        required(),
        minValue(0, 'Doit être au moins 0'),
        maxValue(100, 'Ne peut pas dépasser 100'),
    ];

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.drivers.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="name.first"
                                        resource="users"
                                        label="resources.drivers.fields.name.first"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="name.last"
                                        resource="users"
                                        label="resources.drivers.fields.name.last"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <CivilityInput
                                        source="civility"
                                        label="resources.drivers.fields.civility"
                                    />

                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="users"
                                        label="resources.drivers.fields.email"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="phone"
                                        source="phone"
                                        label="resources.drivers.fields.phone"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <NumberInput
                                        source="commission_rate"
                                        label="resources.drivers.fields.commission"
                                        validate={validateCommission}
                                    />

                                    <ReferenceInput
                                        label="Type de véhiculee"
                                        source="vehicle_type"
                                        reference="vehicles"
                                        validate={requiredValidate}
                                        fullWidth
                                    >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                    <DateInput
                                        type="birthdate"
                                        source="birthdate"
                                        label="resources.drivers.fields.birthdate"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <BooleanInput
                                        source="account_enabled"
                                        label="resources.drivers.fields.account_enabled"
                                        fullWidth
                                    />

                                    <Box mt="1em" />

                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.drivers.fieldGroups.address'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="address1"
                                        resource="users"
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="zipcode"
                                                resource="users"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={2}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                resource="users"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box> */}

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.drivers.fieldGroups.change_password'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.drivers.fieldGroups.pricing'
                                        )}
                                    </Typography>

                                    <SelectInput
                                        source="priceCategory"
                                        choices={[
                                            { id: 'A', name: 'tranche A' },
                                            { id: 'B', name: 'tranche B' },
                                            { id: 'C', name: 'tranche C' },
                                            { id: 'D', name: 'tranche D' },
                                        ]}
                                    />

                                </Box> */}
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="drivers"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const UserDocuments = (props: any) => {
    const { record } = props;
    const classes = useStyles();

    // This map links each field name with its display title
    const fieldMap = {
        cni_file_recto: "CNI File Recto",
        cni_file_verso: "CNI File Verso",
        permis_conduite: 'Permis de conduire',
        immatriculation_vehicule: 'Immatriculation véhicule',
        assurance_vehicule: 'Assurance véhicule',
        inspection_vehicule: 'Inspection véhicule',
        permisET: 'Permis d’éligibilité à travailler',
        antecedent_judiciaire: 'Antécédent Judiciaire',
        picture: 'Profile de Driver',
        // permis_confiance_file_recto: "Permis Confiance Recto",
        // permis_confiance_file_verso: "Permis Confiance Verso",
        // carte_grise_file_recto: "Carte Grise Recto",
        // carte_grise_file_verso: "Carte Grise Verso",
        // permis_conduite_recto: "Permis de conduire Recto",
        // permis_conduite_verso: "Permis de conduire Verso",
    };

    return (
        <Card>
            <CardContent className={classes.customCardContent}>
                <Grid container spacing={2} className={classes.gridContainer}>
                    {Object.entries(fieldMap).map(([key, title]) => (
                        <DocumentLink
                            key={key}
                            title={title}
                            id={record[key]}
                        />
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

const UserEdit = (props: any) => {
    return (
        <Show {...props} component="div" title={<UserTitle />}>
            <TabbedShowLayout>
                <Tab label="Detail">
                    <EditUser {...props} />
                </Tab>
                <Tab label="Documents">
                    <UserDocuments {...props} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default UserEdit;
