import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    Filter,
    List,
    SearchInput,
    downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import FullNameField from '../fields/users/FullNameField';
const UserFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exporter = users => {
    const usersForExport = users.map(user => {
        let created_at = new Date(user.created_at).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
        return {
            name: user.name,
            email: user.email,
            phone: user.phone,
            civility: user.civility,
            created_at: created_at,
        };
    });
    jsonExport(usersForExport, {}, (err, csv) => {
        downloadCSV(csv, 'users'); // download as 'posts.csv` file
    });
};

const UserList = (props: any) => {
    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            bulkActionButtons={false}
            // aside={<UserListAside />}
        >
            <Datagrid optimized rowClick="edit">
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.users.fields.created_at"
                    showTime
                    options={{
                        hourCycle: 'h23',
                    }}
                />
                <FullNameField
                    source="name"
                    label="resources.users.fields.name"
                />
                <TextField
                    source="phone"
                    label="resources.users.fields.phone"
                />
                <TextField
                    source="email"
                    label="resources.users.fields.email"
                />
                <NumberField
                    source="nb_courses"
                    label="resources.users.fields.nb_courses"
                />
            </Datagrid>
        </List>
    );
};

export default UserList;
