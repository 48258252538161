import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    PasswordInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    email,
    BooleanInput,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import Aside from './Aside';
import FullNameField from '../fields/users/FullNameField';
import { validatePasswords } from './UserCreate';
import { Customer, FieldProps } from '../types';

const UserEdit = (props: any) => {
    return (
        <Edit
            title={<UserTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <UserForm />
        </Edit>
    );
};

const UserTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.admins.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="name.first"
                                        resource="admins"
                                        label="resources.admins.fields.name.first"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="name.last"
                                        resource="admins"
                                        label="resources.admins.fields.name.last"
                                        validate={requiredValidate}
                                        fullWidth
                                    />

                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="admins"
                                        label="resources.admins.fields.email"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="phone"
                                        source="phone"
                                        label="resources.admins.fields.phone"
                                        resource="admins"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />

                                    <BooleanInput
                                        source="account_enabled"
                                        label="resources.admins.fields.account_enabled"
                                        fullWidth
                                    />

                                    <Box mt="1em" />

                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.admins.fieldGroups.address'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="address1"
                                        resource="admins"
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="zipcode"
                                                resource="admins"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={2}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                resource="admins"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box> */}

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.admins.fieldGroups.change_password'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="admins"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                resource="admins"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.admins.fieldGroups.pricing'
                                        )}
                                    </Typography>

                                    <SelectInput
                                        source="priceCategory"
                                        choices={[
                                            { id: 'A', name: 'tranche A' },
                                            { id: 'B', name: 'tranche B' },
                                            { id: 'C', name: 'tranche C' },
                                            { id: 'D', name: 'tranche D' },
                                        ]}
                                    />

                                </Box> */}
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="admins"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default UserEdit;
